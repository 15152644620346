<template>
  <component :is="layout">
    <div class="configuration">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-3">
          <ConfigurationNav />
        </div>
        <div class="col-12 col-md-9">
          <h5>Configuration</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Views
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Configure dynamic order views to ensure you have the right
                    data columns available.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'configuration.view' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Views list
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Filters
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Configure filters for your order management and to segment
                    customers.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.segment.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Setup filters
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Tags
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Manage your order and customers tags
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.tags' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Tags list
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    <span
                      role="status"
                      class="c-status c-status-prepend"
                      :class="
                        organization.email
                          ? 'c-status-success'
                          : 'c-status-pending'
                      "
                    />
                    SMTP Settings
                  </p>
                </div>
                <div v-if="organization.email" class="card-body text-center">
                  <div class="row">
                    <div class="col-md-6">
                      <p class="lead text-muted mb-0">
                        <span class="text-dark d-block font-weight-bold mb-0">{{
                          organization.users.length
                        }}</span>
                        <span class="text-small">Members</span>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p class="lead text-muted mb-0">
                        <span class="text-dark d-block font-weight-bold mb-0">{{
                          organization.subscription.name
                        }}</span>
                        <span class="text-small">Active subscription</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else class="card-body text-center">
                  <p class="text-muted mb-0">
                    Set your own SMTP settings instead of using our mail
                    servers.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.email' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Update email settings
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    <span role="status" class="c-status success" />
                    Email templates
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Create or update your email templates to be used in your
                    customer communication.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.email.template' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Update template settings
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    <span role="status" class="c-status success" />Meta field
                    collections
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Update the fields to show in the order view - Automation.app
                    discovers all order data
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'meta.fields.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Meta field collections
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    <span role="status" class="c-status success" />Custom
                    displays
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Add or change custom order displays
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.display.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Custom displays
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Integrations
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    View your integration settings, download integration plugins
                    and find your API keys.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.integrations.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    View integrations
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Referrer tracking plugin for WooCommerce
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Evaluate marketing efforts and create segmentation for
                    groups of users.
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'organization.tracking.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Install Referrer Plugin
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-header border-bottom">
                  <p class="mb-0">
                    Exports
                  </p>
                </div>
                <div class="card-body text-center">
                  <p class="text-muted mb-0">
                    Setup custom data exports. (Exports solution is a "Beta"
                    release)
                  </p>
                </div>
                <div class="card-footer bg-white">
                  <router-link
                    :to="{ name: 'export.index' }"
                    class="btn btn-outline-secondary btn-block"
                  >
                    Update exports
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
export default {
  components: { Default, ConfigurationNav },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("organization/current").then((response) => {
      next((vm) => {
        vm.organization = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      organization: {
        subscription: {},
        plans: [],
        billingAddress: {},
      },
    };
  },
};
</script>
